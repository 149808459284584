import { GET_ORGANIZATION_USAGE_QUERY } from '@/graphql/organization.gql';
import { useOrganizationStore } from '@/stores/organization.store';
import { useRunChecklistStore } from '@/stores/run/run-checklist.store';
import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';

export function FetchOrganizationUsageComponent() {
  // Variables

  // Stores
  const [selectedOrganization, setOrganizationUsage] = useOrganizationStore(
    s => [s.selectedOrganization, s.setOrganizationUsage, s.organizationUsage],
    shallow
  );
  const [
    runChecklistTree,
  ] = useRunChecklistStore(
    s => [
      s.runChecklistTree,
    ],
    shallow
  );

  // GraphQL
  const [getOrganizationUsage] = useLazyQuery(GET_ORGANIZATION_USAGE_QUERY);

  useEffect(() => {
    // Fetch the organization usage, if the organization is free plan
    if (
      selectedOrganization &&
      selectedOrganization?.id &&
      selectedOrganization?.pricing_subscription?.pricing_plan
        ?.pricing_plan_type?.is_freeplan
    ) {
      // Fetch the organization usage
      fetchOrganizationUsage();
    }
  }, [selectedOrganization]);

  useEffect(() => {
      // Fetch the organization usage, if the organization is free plan
      if (
        selectedOrganization &&
        selectedOrganization?.id &&
        selectedOrganization?.pricing_subscription?.pricing_plan
          ?.pricing_plan_type?.is_freeplan &&
          runChecklistTree?.checklist?.is_completed
      ) {
        // Fetch the organization usage
        const currentUsage = useOrganizationStore.getState().organizationUsage;
        useOrganizationStore.getState().setOrganizationUsage({
          ...currentUsage,
          runs: (currentUsage.runs || 0) + 1
        });
      }
    }, [runChecklistTree]);

  async function fetchOrganizationUsage() {
    const { data: organizationUsageData } = await getOrganizationUsage({
      variables: {
        orgId: selectedOrganization?.id
      },
      fetchPolicy: 'network-only'
    });

    if (organizationUsageData) {
      setOrganizationUsage({
        checklists:
          organizationUsageData?.checklist_created?.aggregate?.count,
        runs: organizationUsageData?.run_completed?.aggregate?.count
      });
    }
  }

  return <></>;
}
