import PERMISSIONS_CONSTANTS from '@/constants/permission-constants';
import { DELETE_PROJECT_BY_ID_MUTATION } from '@/graphql/projects.gql';
import { GET_NOT_COMPLETED_RUNS_COUNT_QUERY, GET_NOT_COMPLETED_RUNS_OTHERS_COUNT_QUERY } from '@/graphql/runs.gql';
import { useOrganizationStore } from '@/stores/organization.store';
import { useProjectsStore } from '@/stores/projects.store';
import { Project } from '@/types/projects.type';
import { Run, RunsAggregate } from '@/types/run.type';
import { useMutation, useQuery } from '@apollo/client';
import { Menu, Transition } from '@headlessui/react';
import { useUserData, useUserId } from '@nhost/nextjs';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import CreateProjectPopupComponent from '../projects/create-project-popup.component';
import EditProjectPopupComponent from '../projects/edit-project-popup.component';
import ProjectMembersPopupComponent from '../projects/project-memebers-popup.component';
import { CLCustomButton, CLSecondaryButton } from '../ui-controls';
import ConfirmPopupComponent from '../utils/confirm-popup.component';
import DashboardOrganizationDropdown from './dashboard-organization-dropdown';
import CLTooltip from '../utils/tooltip';
import PlanAndBillingPopupComponent from '../utils/plan-and-billing-popup.component';
import FeedbackSurveyPopupComponent from './feedback-survey-popup.component';
import ROLES_CONSTANTS from '@/constants/roles-constants';

type Props = {
  closeHamburger?: () => void;
};

declare global {
  interface Window {
    Beacon: any;
  }
}

export default function DashboardMenuComponent(props: Props) {
  // Variables
  const router = useRouter();
  const projectId = router.query.projectId;
  const userId = useUserId();
  const userData = useUserData();

  // States
  const [showUpgrade, setShowUpgrade] = useState<boolean>(false);
  const [isPlanAndBillingPopupOpen, setIsPlanAndBillingPopupOpen] =
    useState<boolean>(false);
  const [isFeedbackSurveyPopupOpen, setIsFeedbackSurveyPopupOpen] =
    useState<boolean>(false);
  // const [showAssignedOthers, setShowAssignedOthers ] = useState<boolean>(false);
  // const [filteredAssignedOthersRunCount, setFilteredAssignedOthersRunCount] = useState<number | undefined>();

  // Store
  const { selectedOrganization, organizationPermissions } =
    useOrganizationStore();
  const [isProjectLoading, projects] = useProjectsStore(
    s => [s.isLoading, s.projects],
    shallow
  );

  // GraphQL
  const { data: assignedRunsData } = useQuery<RunsAggregate>(
    GET_NOT_COMPLETED_RUNS_COUNT_QUERY,
    {
      variables: {
        orgId: selectedOrganization?.id,
        userId
      },
      fetchPolicy: 'no-cache'
    }
  );

  // const { data: assignedOthersRunsData } = useQuery(
  //   GET_NOT_COMPLETED_RUNS_OTHERS_COUNT_QUERY,
  //   {
  //     variables: {
  //       orgId: selectedOrganization?.id,
  //       userId
  //     },
  //     fetchPolicy: 'no-cache'
  //   }
  // );

  // useEffect(() => {
  //   if (assignedOthersRunsData) {
  //     const filterRuns = (data: { runs_aggregate: RunsAggregate; runs: Run[] }) => {
  //       const runs = data.runs || []; // Access runs array safely
  
  //       // Calculate total count of valid assignments across all runs
  //       const totalValidAssignments = runs.reduce((totalCount, run) => {
  //         const assignments = run?.runs_assignments || [];
          
  //         // Filter assignments with different userByUserId.id and user.id
  //         const differentUserAssignments = assignments.filter(
  //           (assignment) => assignment?.userByUserId?.id !== assignment?.user?.id
  //         );
  
  //         // Add the count of valid assignments in this run to the total
  //         return totalCount + differentUserAssignments.length;
  //       }, 0); // Initialize totalCount to 0
  
  //       return totalValidAssignments; // Total count of valid assignments
  //     };
    
  //     // Calculate filtered assignments count
  //     const filteredCount = filterRuns(assignedOthersRunsData);
  //     setFilteredAssignedOthersRunCount(filteredCount);
  //     }
  // }, [assignedOthersRunsData]);
  
  

  useEffect(() => {
    if (projects.length > 0) {
      checkProjectLimitation();
    }
  }, [isProjectLoading, projects]);

  // useEffect(() => {
  //   checkRoleAssignedOthersAccess(selectedOrganization?.org_users?.[0]?.custom_role?.id as string)
  // }, [selectedOrganization])

  // function checkRoleAssignedOthersAccess(roleId: string) {
  //   ROLES_CONSTANTS
  //   if (roleId === ROLES_CONSTANTS.ORGANIZATION.GUEST) {
  //     setShowAssignedOthers(false);
  //   } else {
  //     setShowAssignedOthers(true);
  //   }
  // }

  function checkProjectLimitation() {
    if (
      selectedOrganization?.pricing_subscription?.pricing_plan
        ?.pricing_plan_type?.is_freeplan &&
      selectedOrganization?.pricing_subscription?.pricing_plan
        ?.pricing_limitations &&
      selectedOrganization?.pricing_subscription?.pricing_plan
        ?.pricing_limitations.length > 0
    ) {
      const runChecklistLimitation =
        selectedOrganization?.pricing_subscription?.pricing_plan?.pricing_limitations.find(
          limitation => limitation.type === 'projects'
        );
      if (
        runChecklistLimitation &&
        runChecklistLimitation?.value &&
        projects.length &&
        projects.length >= parseInt(runChecklistLimitation?.value)
      ) {
        return setShowUpgrade(true);
      } else {
        return setShowUpgrade(false);
      }
    } else {
      setShowUpgrade(false);
    }
  }

  function CreateProjectComponent({ size }: { size?: string }) {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
      <>
        {!size &&
          (showUpgrade ? (
            // <CLTooltip content="Upgrade to Create Project">
              <CLSecondaryButton onClick={() => {
                setIsPlanAndBillingPopupOpen(true)
              }}>
                <svg
                  className="w-3 mr-2"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7 0C7.55229 0 8 0.447715 8 1V6H13C13.5523 6 14 6.44772 14 7C14 7.55229 13.5523 8 13 8H8V13C8 13.5523 7.55229 14 7 14C6.44772 14 6 13.5523 6 13V8H1C0.447715 8 0 7.55229 0 7C0 6.44772 0.447715 6 1 6H6V1C6 0.447715 6.44772 0 7 0Z"
                    fill="black"
                  />
                </svg>
                New Project
              </CLSecondaryButton>
            // </CLTooltip>
          ) : (
            <CLSecondaryButton
              onClick={() => {
                setIsOpen(true);
              }}
            >
              <svg
                className="w-3 mr-2"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7 0C7.55229 0 8 0.447715 8 1V6H13C13.5523 6 14 6.44772 14 7C14 7.55229 13.5523 8 13 8H8V13C8 13.5523 7.55229 14 7 14C6.44772 14 6 13.5523 6 13V8H1C0.447715 8 0 7.55229 0 7C0 6.44772 0.447715 6 1 6H6V1C6 0.447715 6.44772 0 7 0Z"
                  fill="black"
                />
              </svg>
              New Project
            </CLSecondaryButton>
          ))}

        {size &&
          size == 'sm' &&
          (showUpgrade ? (
            // <CLTooltip content="Upgrade to Create Project">
              <CLCustomButton
                className="flex items-center gap-2 px-3 py-[6px] rounded-3xl group text-black/[.7] hover:text-black/[1] hover:bg-[#FEF5ED] fill-black/[.7] hover:fill-black/[1] w-full"
                onClick={() => {
                  setIsPlanAndBillingPopupOpen(true)
                }}
              >
                <svg
                  className="w-3"
                  viewBox="0 0 14 14"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7 0C7.55229 0 8 0.447715 8 1V6H13C13.5523 6 14 6.44772 14 7C14 7.55229 13.5523 8 13 8H8V13C8 13.5523 7.55229 14 7 14C6.44772 14 6 13.5523 6 13V8H1C0.447715 8 0 7.55229 0 7C0 6.44772 0.447715 6 1 6H6V1C6 0.447715 6.44772 0 7 0Z"
                  />
                </svg>
                New Project
              </CLCustomButton>
            // </CLTooltip>
          ) : (
            <CLCustomButton
              className="flex items-center gap-2 px-3 py-[6px] rounded-3xl group text-black/[.7] hover:text-black/[1] hover:bg-[#FEF5ED] fill-black/[.7] hover:fill-black/[1]"
              onClick={() => {
                setIsOpen(true);
              }}
            >
              <svg
                className="w-3"
                viewBox="0 0 14 14"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7 0C7.55229 0 8 0.447715 8 1V6H13C13.5523 6 14 6.44772 14 7C14 7.55229 13.5523 8 13 8H8V13C8 13.5523 7.55229 14 7 14C6.44772 14 6 13.5523 6 13V8H1C0.447715 8 0 7.55229 0 7C0 6.44772 0.447715 6 1 6H6V1C6 0.447715 6.44772 0 7 0Z"
                />
              </svg>
              New Project
            </CLCustomButton>
          ))}

        <CreateProjectPopupComponent isOpen={isOpen} setIsOpen={setIsOpen} />


        <PlanAndBillingPopupComponent
          isOpen={isPlanAndBillingPopupOpen}
          setIsOpen={setIsPlanAndBillingPopupOpen}
        ></PlanAndBillingPopupComponent>
      </>
    );
  }

  function ShowProjectsComponent() {
    // Store
    const [isLoading, projects, deleteProject] = useProjectsStore(
      s => [s.isLoading, s.projects, s.deleteProject],
      shallow
    );

    // States
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [isEditProjectPopupOpen, setIsEditProjectPopupOpen] =
      useState<boolean>(false);
    const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] =
      useState<boolean>(false);
    const [selectedProject, setSelectedProject] = useState<Project>();
    const [isProjectMembersPopupOpen, setIsProjectMembersPopupOpen] =
      useState<boolean>(false);

    // GraphQL
    const [deleteProjectByIdMutation] = useMutation(
      DELETE_PROJECT_BY_ID_MUTATION
    );

    if (isLoading) {
      return (
        <ul className="grid grid-cols-1 gap-3">
          <li className="bg-[#EDE4E0] rounded-full h-8"></li>
          <li className="bg-[#EDE4E0] rounded-full h-8"></li>
          <li className="bg-[#EDE4E0] rounded-full h-8"></li>
          <li className="bg-[#EDE4E0] rounded-full h-8"></li>
        </ul>
      );
    }

    async function deleteProjectById() {
      if (selectedProject?.id) {
        setIsDeleting(true);

        await deleteProjectByIdMutation({
          variables: {
            projectId: selectedProject.id,
            set: {
              is_deleted: true
            }
          }
        });

        // Refresh the UI
        const nextProjectId = deleteProject(selectedProject.id);

        if (
          router.pathname.startsWith('/projects') ||
          router.pathname.startsWith('/checklist')
        ) {
          if (nextProjectId) {
            router.push('/projects/' + nextProjectId);
          } else {
            router.push('/projects');
          }
        }

        // Close the confirm popup
        setIsDeleting(false);
        setIsDeleteConfirmPopupOpen(false);
      }
    }

    return (
      <>
        {!isLoading && projects && (
          <>
            <div className="flex flex-col gap-1">
              {/* List of projects */}
              {projects.length > 0 &&
                projects.map((project: Project, index) => (
                  <Link
                    href={'/projects/' + project?.id}
                    key={index}
                    onClick={() => {
                      if (props.closeHamburger) {
                        props.closeHamburger();
                      }
                    }}
                  >
                    <div
                      className={`${
                        project?.id && project?.id == projectId
                          ? 'text-black bg-[#FEF5ED]'
                          : 'text-black/[.7]'
                      } px-3 py-1 rounded-3xl flex items-center justify-between gap-2 group hover:text-black/[1] hover:bg-[#FEF5ED]`}
                    >
                      {/* Project name */}
                      <div className="truncate">{project?.name}</div>

                      {/* Dropdown */}
                      <div
                        className="invisible group-hover:visible"
                        onClick={e => {
                          e.stopPropagation();
                          e.nativeEvent.preventDefault();
                        }}
                      >
                        <Menu
                          as="div"
                          className="relative inline-block text-left text-sm"
                        >
                          <Menu.Button className="flex w-6 h-6 items-center justify-center rounded-full hover:bg-slate-100 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                            <svg
                              width="3"
                              viewBox="0 0 4 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M4 2C4 3.10457 3.10457 4 2 4C0.895431 4 0 3.10457 0 2C0 0.895431 0.895431 0 2 0C3.10457 0 4 0.895431 4 2ZM4 8C4 9.10457 3.10457 10 2 10C0.895431 10 0 9.10457 0 8C0 6.89543 0.895431 6 2 6C3.10457 6 4 6.89543 4 8ZM2 16C3.10457 16 4 15.1046 4 14C4 12.8954 3.10457 12 2 12C0.895431 12 0 12.8954 0 14C0 15.1046 0.895431 16 2 16Z"
                                fill="black"
                                fillOpacity="0.4"
                              />
                            </svg>
                          </Menu.Button>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 mt-1 w-44 origin-top-right z-10 divide-y divide-gray-100 rounded-sm bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="px-1 py-1">
                                {/* Add Member */}
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      className={`${
                                        active ? '' : 'text-black/[.6]'
                                      } group flex w-full items-center rounded-md px-2 py-2 font-normal hover:bg-slate-100`}
                                      onClick={() => {
                                        setSelectedProject(project);
                                        setIsProjectMembersPopupOpen(true);
                                      }}
                                    >
                                      Invite Members
                                    </button>
                                  )}
                                </Menu.Item>

                                {/* Edit */}
                                {organizationPermissions?.[
                                  PERMISSIONS_CONSTANTS.UPDATE_PROJECT
                                ] && (
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        className={`${
                                          active ? '' : 'text-black/[.6]'
                                        } group flex w-full items-center rounded-md px-2 py-2 font-normal hover:bg-slate-100`}
                                        onClick={() => {
                                          setSelectedProject(project);
                                          setIsEditProjectPopupOpen(true);
                                        }}
                                      >
                                        Edit
                                      </button>
                                    )}
                                  </Menu.Item>
                                )}

                                {/* Delete */}
                                {organizationPermissions?.[
                                  PERMISSIONS_CONSTANTS.DELETE_PROJECT
                                ] && (
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        className={`${
                                          active ? '' : 'text-black/[.6]'
                                        } group flex w-full items-center rounded-md px-2 py-2 font-normal hover:bg-slate-100`}
                                        onClick={() => {
                                          setSelectedProject(project);
                                          setIsDeleteConfirmPopupOpen(true);
                                        }}
                                      >
                                        Delete
                                      </button>
                                    )}
                                  </Menu.Item>
                                )}
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                  </Link>
                ))}

              {/* Create new project */}
              {projects.length > 0 &&
                organizationPermissions?.[
                  PERMISSIONS_CONSTANTS.CREATE_PROJECT
                ] && <CreateProjectComponent size="sm" />}

              {/* Create new project */}
              {projects.length == 0 &&
                organizationPermissions?.[
                  PERMISSIONS_CONSTANTS.CREATE_PROJECT
                ] && <CreateProjectComponent />}
            </div>

            {selectedProject?.id && (
              <EditProjectPopupComponent
                projectId={selectedProject.id}
                projectName={selectedProject?.name}
                isOpen={isEditProjectPopupOpen}
                setOpen={setIsEditProjectPopupOpen}
              />
            )}

            <ConfirmPopupComponent
              title="Delete this project?"
              description="Your project and all of its checklist and runs will be deleted."
              buttonText="Delete"
              buttonClick={deleteProjectById}
              isProcessing={isDeleting}
              isOpen={isDeleteConfirmPopupOpen}
              setOpen={setIsDeleteConfirmPopupOpen}
            />

            {selectedProject?.id && isProjectMembersPopupOpen && (
              <ProjectMembersPopupComponent
                projectId={selectedProject?.id}
                isOpen={isProjectMembersPopupOpen}
                setIsOpen={setIsProjectMembersPopupOpen}
              />
            )}
          </>
        )}
      </>
    );
  }

  function openHelpScout() {
    // Open Beacon
    if (window?.Beacon) {
      window.Beacon('init', 'dafb4f08-4312-4bec-873a-e7f9aa5939af');
      // Open/Close the container
      window.Beacon('toggle');

      setTimeout(() => {
        const helpScoutContainerElement: any =
          document.querySelector('.BeaconContainer');
        if (helpScoutContainerElement) {
          if (window.innerWidth <= 450) {
            helpScoutContainerElement.style.top = '';
            helpScoutContainerElement.style.left = '';
            helpScoutContainerElement.style.bottom = '';
            helpScoutContainerElement.style.right = '';
          } else {
            helpScoutContainerElement.style.top = 'auto';
            helpScoutContainerElement.style.left = '20px';
            helpScoutContainerElement.style.bottom = '20px';
            helpScoutContainerElement.style.right = '';
          }
        }
      }, 100);
    }
  }

  return (
    <>
      {/* Profile */}
      <div className="sticky top-0 cl_secondary_background z-[1]">
        <DashboardOrganizationDropdown />
      </div>

      {/* Projects & Settings */}
      <div className="divide-y divide-gray-100">
        {/* Projects */}
        <div className="px-2 py-2 text-sm">
          {selectedOrganization ? (
            <ShowProjectsComponent />
          ) : (
            <>
              <ul className="grid grid-cols-1 gap-3">
                <li className="bg-[#EDE4E0] rounded-full h-8"></li>
                <li className="bg-[#EDE4E0] rounded-full h-8"></li>
                <li className="bg-[#EDE4E0] rounded-full h-8"></li>
                <li className="bg-[#EDE4E0] rounded-full h-8"></li>
              </ul>
            </>
          )}
        </div>

        {/* Toolbar */}
        {!isProjectLoading && projects && projects?.length > 0 && (
          <div className="px-2 divide-y divide-gray-100 sticky bottom-0 cl_secondary_background text-sm">
            <div className="py-2 flex flex-col gap-1">
              {/* Assigned To Me */}
              <Link
                href="/assigned"
                onClick={() => {
                  if (props.closeHamburger) {
                    props.closeHamburger();
                  }
                }}
              >
                <div
                  className={`${
                    router?.pathname && router?.pathname == '/assigned'
                      ? 'text-black bg-[#FEF5ED]'
                      : 'text-black/[.7]'
                  } flex flex-col gap-2 px-3 py-2 rounded-3xl hover:text-black/[1] hover:bg-[#FEF5ED]`}
                >
                  Assigned To Me
                  {assignedRunsData?.runs_aggregate?.aggregate &&
                  assignedRunsData?.runs_aggregate?.aggregate?.count &&
                  assignedRunsData?.runs_aggregate?.aggregate?.count > 0 ? (
                    <>
                      {' (' +
                        assignedRunsData?.runs_aggregate?.aggregate?.count +
                        ')'}
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </Link>
              {/* {showAssignedOthers && (<Link
                href="/assigned-to-others"
                onClick={() => {
                  if (props.closeHamburger) {
                    props.closeHamburger();
                  }
                }}
              >
                <div
                  className={`${
                    router?.pathname &&
                    router?.pathname == '/assigned-to-others'
                      ? 'text-black bg-[#FEF5ED]'
                      : 'text-black/[.7]'
                  } flex flex-col gap-2 px-3 py-2 rounded-3xl hover:text-black/[1] hover:bg-[#FEF5ED]`}
                >
                  Assigned To Others
                  {filteredAssignedOthersRunCount &&
                  filteredAssignedOthersRunCount > 0 ? (
                    <>
                      {' (' +
                        filteredAssignedOthersRunCount +
                        ')'}
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </Link>)} */}
            </div>

            <div className="py-2 flex flex-col gap-1">
              {/* Settings */}
              <Link
                href="/settings"
                onClick={() => {
                  if (props.closeHamburger) {
                    props.closeHamburger();
                  }
                }}
              >
                <div
                  className={`${
                    router?.pathname && router?.pathname.startsWith('/settings')
                      ? 'text-black bg-[#FEF5ED]'
                      : 'text-black/[.7]'
                  } flex flex-col gap-2 px-3 py-2 rounded-full hover:text-black/[1] hover:bg-[#FEF5ED]`}
                >
                  Settings
                </div>
              </Link>

              {/* Academy */}
              <Link
                href="/academy"
                onClick={() => {
                  if (props.closeHamburger) {
                    props.closeHamburger();
                  }
                }}>
                  <div
                    className={`${
                      router?.pathname && router?.pathname.startsWith('/academy')
                        ? 'text-black bg-[#FEF5ED]'
                        : 'text-black/[.7]'
                    } flex flex-col gap-2 px-3 py-2 rounded-3xl hover:text-black/[1] hover:bg-[#FEF5ED]`}
                    >
                      Academy
                    </div>
                </Link>

              {/* Dashboard */}
              <Link
                href="/dashboard"
                onClick={() => {
                  if (props.closeHamburger) {
                    props.closeHamburger();
                  }
                }}
              >
                <div
                  className={`${
                    router?.pathname && router?.pathname == '/dashboard'
                      ? 'text-black bg-[#FEF5ED]'
                      : 'text-black/[.7]'
                  } flex flex-col gap-2 px-3 py-2 rounded-3xl hover:text-black/[1] hover:bg-[#FEF5ED]`}
                >
                  Dashboard
                </div>
              </Link>

              {/* Feedback */}
              <CLCustomButton
                className="flex items-start flex-col gap-2 px-3 py-2 rounded-full text-black/[0.7] hover:text-black/[1] hover:bg-[#FEF5ED]"
                onClick={() => {
                  setIsFeedbackSurveyPopupOpen(true);
                }}
              >
                Share Feedback
              </CLCustomButton>

              {/* Help */}
              <CLCustomButton
                className="flex items-start flex-col gap-2 px-3 py-2 rounded-full text-black/[0.7] hover:text-black/[1] hover:bg-[#FEF5ED]"
                onClick={() => {
                  openHelpScout();
                }}
              >
                Help
              </CLCustomButton>

              {/* Review Us*/}
              <CLCustomButton
                className="flex items-start flex-col gap-2 px-3 py-2 rounded-full text-black/[0.7] hover:text-black/[1] hover:bg-[#FEF5ED] "
                onClick={() => {
                  window.open(
                    'https://reviews.capterra.com/products/new/aa877bb2-6619-412b-a870-dc0d758379b3/07977660-db07-4341-b660-fe232748afd6?lang=en&lid=286438',
                    '_blank'
                  );
                }}
              >
                <div className="flex items-center gap-10">
                  Review Us{' '}
                  <div className="p-1 rounded-md bg-[#ffb82a]">$20</div>
                </div>
              </CLCustomButton>
            </div>
          </div>
        )}
      </div>

      <FeedbackSurveyPopupComponent
        isOpen={isFeedbackSurveyPopupOpen}
        setOpen={setIsFeedbackSurveyPopupOpen}
        orgId={selectedOrganization?.id}
        email={userData?.email}
      ></FeedbackSurveyPopupComponent>
    </>
  );
}
