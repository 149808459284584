import { CLPrimaryCustomLabel } from '@/components/ui-controls';
import CLButton from '@/components/ui-controls/default-ui-controls/button';
import CommonConstants from '@/constants/common-constants';
import { UPDATE_RUN_CHECKLIST_STATUS_MULTIPLE_MUTATION } from '@/graphql/runs.gql';
import {
  markParentAsCompleted,
  markParentAsNotCompleted,
  markAllChildAsCompletedOrNotCompleted as markSelfAndAllChildAsCompletedOrNotCompleted
} from '@/services/runs/runs.service';
import { usePublicRunStore } from '@/stores/public-run/public-run-checklist.store';
import { useRunChecklistStore } from '@/stores/run/run-checklist.store';
import { RunChecklist } from '@/types/run.type';
import { useMutation } from '@apollo/client';
import { useUserId } from '@nhost/nextjs';
import axios from 'axios';
import { useState } from 'react';
import { shallow } from 'zustand/shallow';
import CLTooltip from '../../../utils/tooltip';
import { RunChecklistTree } from '../../run-by-id.component';
import TaskDetailsPopup from './task-details.popup.component';

export default function ClassicViewTaskListComponent() {
  // Variables

  // Store
  const [
    runChecklistTree,
    statusIndicators,
    isPreview,
    isPublic,
    isBuilderPreview,
    isSingleResponse
  ] = useRunChecklistStore(
    s => [
      s.runChecklistTree,
      s.statusIndicators,
      s.isPreview,
      s.isPublic,
      s.isBuilderPreview,
      s.isSingleResponse
    ],
    shallow
  );

  return (
    <>
      <div
        className={
          'bg-white overflow-auto rounded-lg min-h-full' +
          (isPublic ? ' p-4 lg:p-6' : isPreview ? ' p-4 pt-4' : ' p-4') + 
          (isBuilderPreview ? ' rounded-none rounded-br-lg !pt-0 pl-0 pr-0 h-[10vh] bg-white' : '')
        }
      >
       {isBuilderPreview && (
            <div className="flex justify-between w-full text-black/50 font-semibold items-center px-2 pl-0 py-2 pb-4 text-sm h-4 z-10 sticky top-0 bg-white"
            style={{
            left: 0,      // Align to the left edge
            marginLeft: "calc(-1 * var(--tw-container-left, 0px))", // Adjust for container padding
            marginRight: "calc(-1 * var(--tw-container-left, 0px))" // Adjust for container padding
            }}
            >
            <div className='text-sm'>Preview</div>
            {/* <div className='text-xs items-start justify-start italic'>Any changes you make here won&apos;t be saved!</div> */}
            </div>
        )}
        {/* Header */}
        <div className={"flex flex-nowrap gap-2 mb-4 font-medium" + (isBuilderPreview ? ' sticky top-6 bg-white !gap-0 mb-0 z-10' : '')}>
          {/* Title */}
          <div
            className={
              'flex-1 lg:min-w-[300px] text-base ' +
              (isBuilderPreview ? 'text-black/70 flex pb-1 border-b-2 border-gray-300 !min-w-[230px]' : '')
            }
          >
            <span
              style={
              isBuilderPreview
                ? {
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'normal', // Ensure lines break naturally
                  maxHeight: 'calc(1.3em * 2)', // Adjust based on the line height
                  lineHeight: '1.3em', // Match the calculated height for precise clamping
                }
                : (isSingleResponse ? { padding: '0 0.75rem' } : undefined) 
              }
            >
            {!isSingleResponse ? runChecklistTree?.checklist?.title || '' : 'Tasks'}
            </span>
            {/* {isBuilderPreview && (
                <div className="flex rounded-3xl text-gray-500 items-center px-2 pl-2 ml-0 mr-5 text-[14px] h-4">
                Preview
                </div>
            )} */}
            </div>

          {/* Status indicators */}
          {statusIndicators?.map((statusIndicator, index) => (
            <div
              key={index}
              className={
              'hidden lg:block ' +
              (isBuilderPreview
                ? 'max-w-[84px] min-w-[83px] pl-2 pr-0.5 text-sm text-black/70 pb-1 z-5 bg-white border-b-2 border-gray-300'
                : 'min-w-[95px]')
              }
            >
              <div className="flex items-center justify-center text-center">
              <span className='whitespace-pre-wrap overflow-hidden break-words'>
                {statusIndicator?.name}
              </span>
              </div>
            </div>
          ))}
        </div>

        {/* List of tasks */}
        {runChecklistTree &&
          runChecklistTree?.children &&
          runChecklistTree?.children?.length > 0 && (
            <>
              <div>
                <RenderRunChecklist
                  node={runChecklistTree}
                  parentDisplayNumber={undefined}
                  isBuilderPreview={isBuilderPreview}
                />
              </div>
            </>
          )}
      </div>
    </>
  );
}

function RenderRunChecklist({
  node,
  parentDisplayNumber,
  isBuilderPreview,
  isChild,
}: {
  node: RunChecklistTree;
  parentDisplayNumber: string | undefined;
  isBuilderPreview?: boolean;
  isChild?: boolean;
}) {
  return (
    <>
      {/* Loop all children  */}
      {node?.children?.map((childNode, index) => (
        <div key={childNode?.checklist?.id + '-' + index}>
          <RenderEachRunChecklist
            checklist={childNode?.checklist}
            node={childNode}
            displayNumber={(parentDisplayNumber || '') + (index + 1) + '.'}
            isBuilderPreview={isBuilderPreview}
            isChild={isChild}
          />

          {/* Does this node have children? */}
          {childNode?.children && childNode?.children?.length > 0 && (
            <div className={"pl-4" + (isBuilderPreview ? ' !pl-0':'')}>
              <RenderRunChecklist
                node={childNode}
                parentDisplayNumber={
                  (parentDisplayNumber || '') + (index + 1) + '.'
                }
                isBuilderPreview={isBuilderPreview}
                isChild={true}
              />
            </div>
          )}
        </div>
      ))}
    </>
  );
}

function RenderEachRunChecklist({
  checklist,
  node,
  displayNumber,
  isBuilderPreview,
  isChild
}: {
  checklist: RunChecklist;
  node: RunChecklistTree;
  displayNumber: string | undefined;
  isBuilderPreview?: boolean;
  isChild?: boolean;
}) {
  // Variables

  return (
    <>
      {node?.children && node?.children?.length > 0 ? (
        <>
          {/* Parent checklist */}
          <RenderParentRunChecklist
            checklist={node?.checklist}
            node={node}
            displayNumber={displayNumber}
            isBuilderPreview={isBuilderPreview}
            isChild={isChild}
          />
        </>
      ) : (
        <>
          {/* Child checklist */}
          <RenderChildRunChecklist
            checklist={node?.checklist}
            node={node}
            displayNumber={displayNumber}
            isBuilderPreview={isBuilderPreview}
            isChild={isChild}
          />
        </>
      )}
    </>
  );
}

function RenderParentRunChecklist({
  checklist,
  node,
  displayNumber,
  isBuilderPreview,
  isChild
}: {
  checklist: RunChecklist;
  node: RunChecklistTree;
  displayNumber: string | undefined;
  isBuilderPreview?: boolean;
  isChild?: boolean;
}) {
  return (
    <>
      {/* Layout */}
      <div
        className={
          'group  min-w-fit rounded-lg cl_primary_background' +
          (isBuilderPreview ? (' my-3.5 py-3.5' + (isChild ? ' ml-4 ' : '') ) : ' my-4 py-4')
        }
      >
        {/* Task title */}
        <div className="flex items-center">
          <RunChecklistTaskTitle
            checklist={node?.checklist}
            node={node}
            displayNumber={displayNumber}
            isParent={true}
            isBuilderPreview={isBuilderPreview}
          />

          {/* Toolbar */}
          <div className="flex items-center min-h-[24px]">
            <div className="block lg:hidden lg:group-hover:block">
              <RunChecklistToolBar node={node} checklist={node?.checklist} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function RenderChildRunChecklist({
  checklist,
  node,
  displayNumber,
  isBuilderPreview,
  isChild
}: {
  checklist: RunChecklist;
  node: RunChecklistTree;
  displayNumber: string | undefined;
  isBuilderPreview?: boolean;
  isChild?: boolean;
}) {
  // Variables

  // Nhost hooks
  const userId = useUserId();

  // States
  const [isSaving, setIsSaving] = useState<boolean>(false);

  // Store
  const [
    statusIndicators,
    refreshRunChecklistTree,
    isPreview,
    isPublic,
    isStatusIndicatorDisabled
  ] = useRunChecklistStore(
    s => [
      s.statusIndicators,
      s.refreshRunChecklistTree,
      s.isPreview,
      s.isPublic,
      s.isStatusIndicatorDisabled
    ],
    shallow
  );
  const [publicRunAccessToken] = usePublicRunStore(
    s => [s.publicRunAccessToken],
    shallow
  );

  // GraphQL
  const [updateRunChecklistStatusMultipleMutation] = useMutation(
    UPDATE_RUN_CHECKLIST_STATUS_MULTIPLE_MUTATION
  );

  async function onChangeOfStatusIndicator(
    statusIndicatorId: string,
    status: boolean,
    selectedRunNode: RunChecklistTree
  ) {
    if (selectedRunNode) {
      const updatableRunChecklist: RunChecklist[] = [];

      // Mark self and all child as completed or not-completed
      markSelfAndAllChildAsCompletedOrNotCompleted(
        selectedRunNode,
        status,
        updatableRunChecklist,
        statusIndicatorId
      );

      // Mark all the parents as not-completed
      if (status === false) {
        markParentAsNotCompleted(selectedRunNode.parent, updatableRunChecklist);
      } else {
        // Mark parent as completed, if all other child status is completed
        markParentAsCompleted(selectedRunNode.parent, updatableRunChecklist);
      }

      // Refresh the checklist view also
      refreshRunChecklistTree();

      // Don't update the status on database, if it is preview
      if (!isPreview && !isPublic) {
        await updateRunChecklistStatus(updatableRunChecklist);
      } else if (isPublic) {
        await updatePublicRunChecklistStatus(updatableRunChecklist);
      }
    }
  }

  async function updateRunChecklistStatus(
    updatableRunChecklist: RunChecklist[]
  ) {
    setIsSaving(true);

    // Update completedBy
    updatableRunChecklist.map(runChecklist => {
      if (runChecklist?.is_completed) {
        runChecklist.completed_by = userId;
        runChecklist.completed_at = new Date().toISOString();
      } else {
        runChecklist.completed_by = undefined;
        runChecklist.completed_at = undefined;
      }

      return runChecklist;
    });

    // Insert and update
    const { data, errors } = await updateRunChecklistStatusMultipleMutation({
      variables: {
        runChecklist: updatableRunChecklist
      }
    });

    setIsSaving(false);
  }

  async function updatePublicRunChecklistStatus(
    updatableRunChecklist: RunChecklist[]
  ) {
    try {
      setIsSaving(true);

      // Update status
      const payload = {
        publicRunChecklist: updatableRunChecklist
      };

      // Resume the run
      const response = await axios.post(
        CommonConstants.API_PREFIX + '/public/run/update-status',
        payload,
        {
          headers: {
            authorization: 'Bearer ' + publicRunAccessToken
          }
        }
      );

      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);

      throw error;
    }
  }

  return (
    <>
      <div
        className={
          'group  min-w-fit rounded-lg hover:bg-gray-100' +
          (isBuilderPreview ? ' my-2 py-2' : ' my-4 py-4')
        }
      >
        {/* Task title and checkbox */}
        <div className="flex flex-nowrap gap-4 lg:gap-2 flex-col lg:flex-row">
          {/* Task title */}
          <div className={"flex-1 lg:min-w-[300px] flex items-center" + (isBuilderPreview ? ' !min-w-[230px]' : '')}>
            <RunChecklistTaskTitle
              checklist={node?.checklist}
              node={node}
              displayNumber={displayNumber}
              isParent={false}
              isBuilderPreview={isBuilderPreview}
              isChild={isChild}
            />

            {/* Toolbar */}
            <div className="flex items-center min-h-[24px]">
              <div className="block lg:hidden lg:group-hover:block">
                <RunChecklistToolBar
                  node={node}
                  checklist={node?.checklist}
                  onChangeOfStatusIndicator={onChangeOfStatusIndicator}
                  isSaving={isSaving}
                />
              </div>
            </div>
          </div>

          {/* Status indicators for desktop screen */}
          <div className="hidden lg:flex gap-2 xl:gap-3">
            {statusIndicators?.map((statusIndicator, index) => (
              <div
                key={index}
                className={
                  'flex justify-center ' +
                  (isBuilderPreview
                    ? 'min-w-[71px] max-w-[71px] text-sm'
                    : 'min-w-[100px]')
                }
              >
                <input
                  type="checkbox"
                  className={
                    'bg-white border-black text-[#474554] focus:ring-black/20 rounded cursor-pointer disabled:opacity-50 disabled:pointer-events-none' +
                    (isBuilderPreview ? ' w-5 h-5' : ' w-6 h-6')
                  }
                  checked={
                    (checklist?.is_completed &&
                      (statusIndicator.id ===
                        checklist.checklist_status_indicators_id ||
                        !statusIndicator.id)) ||
                    false
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onChangeOfStatusIndicator(
                      statusIndicator?.id || '',
                      event.target.checked,
                      node
                    );
                  }}
                  disabled={isSaving || isStatusIndicatorDisabled}
                />
              </div>
            ))}
          </div>

          {/* Status indicators for mobile screen */}
          <div className="flex lg:hidden gap-4 flex-col px-4">
            {statusIndicators?.map((statusIndicator, index) => (
              <div key={index} className="flex gap-2">
                <input
                  type="checkbox"
                  id={
                    checklist?.id +
                    '-' +
                    statusIndicator?.id +
                    '-status-indicator'
                  }
                  className={
                    'bg-white border-black text-[#474554] focus:ring-black/20 rounded cursor-pointer disabled:opacity-50 disabled:pointer-events-none' +
                    (isBuilderPreview ? ' w-5 h-5' : ' w-6 h-6')
                  }
                  checked={
                    (checklist?.is_completed &&
                      (statusIndicator.id ===
                        checklist.checklist_status_indicators_id ||
                        !statusIndicator.id)) ||
                    false
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onChangeOfStatusIndicator(
                      statusIndicator?.id || '',
                      event.target.checked,
                      node
                    );
                  }}
                  disabled={isSaving || isStatusIndicatorDisabled}
                />
                <CLPrimaryCustomLabel
                  className="flex items-center cursor-pointer pt-[2px]"
                  htmlFor={
                    checklist?.id +
                    '-' +
                    statusIndicator?.id +
                    '-status-indicator'
                  }
                >
                  {statusIndicator?.name}
                </CLPrimaryCustomLabel>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

function RunChecklistTaskTitle({
  checklist,
  node,
  displayNumber,
  isParent,
  isBuilderPreview,
  isChild,
}: {
  checklist: RunChecklist;
  node: RunChecklistTree;
  displayNumber: string | undefined;
  isParent: boolean;
  isBuilderPreview?: boolean;
  isChild?: boolean;
}) {
  // Variables

  return (
    <>
      <div
        className={
          'flex flex-col gap-2 pl-4 pr-2' +
          (isBuilderPreview ? (' opacity-70' + (isChild ? ' !pl-8 ' : '')) : '')
        }
      >
        {/* Title */}
        <div className={isParent ? 'font-medium' : ''}>
          {displayNumber} {checklist?.title}
        </div>
      </div>
    </>
  );
}

function RunChecklistToolBar({
  node,
  checklist,
  onChangeOfStatusIndicator,
  isSaving
}: {
  node: RunChecklistTree;
  checklist: RunChecklist;
  onChangeOfStatusIndicator?: (
    statusIndicatorId: string,
    status: boolean,
    selectedRunNode: RunChecklistTree
  ) => void;
  isSaving?: boolean;
}) {
  // Variables

  // States
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      {/* List of tabs */}
      <div className="flex gap-2">
        {/* Instructions */}
        {checklist?.id && (
          //  Details
          <div>
            <CLTooltip content="Details">
              <CLButton
                className="flex gap-1 justify-center  rounded-full w-6 h-6  items-center hover:bg-[#c1cdd9] "
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                <svg
                  className="w-3 h-3"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.11111 10C0.805556 10 0.543981 9.8912 0.326389 9.67361C0.108796 9.45602 0 9.19444 0 8.88889V1.11111C0 0.805556 0.108796 0.543981 0.326389 0.326389C0.543981 0.108796 0.805556 0 1.11111 0H4.44444C4.60185 0 4.7338 0.0532407 4.84028 0.159722C4.94676 0.266204 5 0.398148 5 0.555556C5 0.712963 4.94676 0.844907 4.84028 0.951389C4.7338 1.05787 4.60185 1.11111 4.44444 1.11111H1.11111V8.88889H8.88889V5.55556C8.88889 5.39815 8.94213 5.2662 9.04861 5.15972C9.15509 5.05324 9.28704 5 9.44444 5C9.60185 5 9.7338 5.05324 9.84028 5.15972C9.94676 5.2662 10 5.39815 10 5.55556V8.88889C10 9.19444 9.8912 9.45602 9.67361 9.67361C9.45602 9.8912 9.19444 10 8.88889 10H1.11111ZM8.88889 1.88889L4.11111 6.66667C4.00926 6.76852 3.87963 6.81944 3.72222 6.81944C3.56481 6.81944 3.43519 6.76852 3.33333 6.66667C3.23148 6.56481 3.18056 6.43519 3.18056 6.27778C3.18056 6.12037 3.23148 5.99074 3.33333 5.88889L8.11111 1.11111H6.66667C6.50926 1.11111 6.37731 1.05787 6.27083 0.951389C6.16435 0.844907 6.11111 0.712963 6.11111 0.555556C6.11111 0.398148 6.16435 0.266204 6.27083 0.159722C6.37731 0.0532407 6.50926 0 6.66667 0H10V3.33333C10 3.49074 9.94676 3.62269 9.84028 3.72917C9.7338 3.83565 9.60185 3.88889 9.44444 3.88889C9.28704 3.88889 9.15509 3.83565 9.04861 3.72917C8.94213 3.62269 8.88889 3.49074 8.88889 3.33333V1.88889Z"
                    fill="black"
                    fillOpacity="0.6"
                  />
                </svg>
              </CLButton>
            </CLTooltip>
          </div>
        )}

        {/* Comment Indicator */}
        {/* <div className="flex gap-1 justify-center rounded-full w-6 h-6 items-center">
          <div className='text-xs'>2</div>
          <svg
            className="w-3 h-3"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 10V1C0 0.725 0.0979167 0.489583 0.29375 0.29375C0.489583 0.0979167 0.725 0 1 0H9C9.275 0 9.51042 0.0979167 9.70625 0.29375C9.90208 0.489583 10 0.725 10 1V7C10 7.275 9.90208 7.51042 9.70625 7.70625C9.51042 7.90208 9.275 8 9 8H2L0 10ZM1.575 7H9V1H1V7.5625L1.575 7Z"
              fill="black"
              fill-opacity="0.6"
            />
          </svg>
        </div> */}
      </div>

      {/* Task details popup */}
      <TaskDetailsPopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        node={node}
        checklist={node?.checklist}
        onChangeOfStatusIndicator={onChangeOfStatusIndicator}
        isSaving={isSaving}
      />
    </>
  );
}
